import { mapData } from "@/utilities/DataMapping";

export interface IArtworXRetailerField {
    id: number;
    fieldName: string;
    dataType: string;
    previewDataExample: string;
    fieldDescription: string;
}

export class ArtworXRetailerField implements IArtworXRetailerField {

    constructor(data?: IArtworXRetailerField) {
        if (data) this.update(data);
    }

    update(data: IArtworXRetailerField): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    fieldName: string = "";
    dataType: string = "";
    previewDataExample: string = "";
    fieldDescription: string = "";
}
