
































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { ArtworXRetailerField } from "@/model/ArtworXRetailerField";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class ArtworXPreviewFieldDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("artworkForm") private readonly artworkForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private field: ArtworXRetailerField = new ArtworXRetailerField();

    //
    // -- methods
    //

    async edit(id: number): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit ArtworX Preview Field";
        const responseData = await apiClient.get(`api/artworx/loadField?id=${id}`);
        this.field.update(responseData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.field);
        this.artworkForm?.resetValidation();
    }

    private async save() {
        if (!this.artworkForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const saveResponse: ISaveResponse = await apiClient.post("/api/artworx/saveField", this.field, "field-save");
        if (saveResponse.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
