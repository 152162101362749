

















import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ArtworXPreviewFieldDialogue from "@/vue/components/dialogues/ArtworXPreviewFieldDialogue.vue";
import { ArtworXRetailerField, IArtworXRetailerField } from "@/model/ArtworXRetailerField";

@Component({
    components: { 
        ApiButton,
        ArtworXPreviewFieldDialogue
    }
})

export default class ArtworXPreviewFields extends Vue {

    async mounted(): Promise<void> { 
        this.loadFields();
    }
    
    //
    // -- properties
    //

    private artworXHeaders = [
        { text: "Database Field Name", value: "fieldName", sortable: true },
        { text: "Data Type", value: "dataType", sortable: false },
        { text: "Data Example", value: "previewDataExample", align: "center", sortable: false },
        { text: "Description", value: "fieldDescription", sortable: false }
    ];

    private artworXList: Array<ArtworXRetailerField> = [];

    //
    // -- methods
    //

    private async loadFields() {
        this.artworXList = [];
        const response = await apiClient.get("/api/artworx/loadAllPreviewFields");
        this.artworXList.push(...response.map((a: IArtworXRetailerField) => new ArtworXRetailerField(a)));
    }

    edit(artworX: ArtworXRetailerField): void {
        const dialog: ArtworXPreviewFieldDialogue = this.$refs.artworXPreviewFieldDialogue as ArtworXPreviewFieldDialogue;
        dialog.edit(artworX.id);
    }
}
